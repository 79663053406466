

















































































































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "@/axios";
//import firebase from "firebase";

@Component
export default class GuestMessages extends Vue {
  variable = null;
  allMessages = [];
  errors = [];
  newMessage = "";
  lastUpdated = "";
  orderBy = "Oldest";
  currentValue: "";
  inputHeight: "0";

  markAsRead(hk, gk, data) {
    const Url = `${process.env.VUE_APP_APIURL}/Chat/allread/${hk}/${gk}`;
    axios.post(Url, data).then(response => {
      if (response.data) {
        console.log(response.data);
      }
    });
  }

  get HotelData() {
    return this.$store.state.hotelInfo;
  }

  get User() {
    return this.$store.state.authProfile;
  }

  get getMessages() {
    const hk = this.$store.state.hotelKey;
    const gk = this.$store.state.guestKey;
    const uid = this.$store.state.authProfile.uid;
    const Url = `${process.env.VUE_APP_APIURL}/Chat/get/${hk}/${gk}`;
    const data = { uid: uid ? uid : null };
    axios
      .post(Url, data)
      .then(response => {
        if (response.data) {
          if (response.data.Messages) {
            this.allMessages = response.data.Messages;
            this.orderBy = "Oldest";
            this.$store.commit("setUnreadMessages", false);
            this.markAsRead(hk, gk, data);
          }
          if (response.data.lastUpdated)
            this.lastUpdated = response.data.lastUpdated;
        }
      })
      .then(() => {
        this.scrollToElements();
      })
      .catch(e => {
        this.errors.push(e);
      });
    return hk;
  }

  sortby(Order) {
    this.orderBy = Order;
    if (this.allMessages) this.allMessages = this.allMessages.slice().reverse();
  }

  addComment(e) {
    e.preventDefault();
    if (this.newMessage.length === 0) return;
    const hk = this.$store.state.hotelKey;
    const gk = this.$store.state.guestKey;
    const uid = this.$store.state.authProfile.uid;
    const Url = `${process.env.VUE_APP_APIURL}/Chat/insert/${hk}/${gk}`;
    const data = {
      uid: uid ? uid : null,
      message: this.newMessage ? this.newMessage : null
    };
    axios
      .post(Url, data)
      .then(response => {
        if (response.data) {
          if (response.data.Messages) {
            this.allMessages = response.data.Messages;
            this.orderBy = "Oldest";
          }
          if (response.data.lastUpdated) {
            this.lastUpdated = response.data.lastUpdated;
            this.newMessage = "";
          }
        }
      })
      .then(() => {
        this.scrollToElements();
      })
      .catch(e => {
        this.errors.push(e);
      });
    return hk;
  }

  scrollToElements() {
    const el = this.$el.getElementsByClassName("scroll-to-me")[0];
    if (el) {
      el.scrollIntoView();
      const newEl = this.$el.getElementsByClassName("scroll-to-me-second")[0];
      let p = newEl.parentElement;
      p = p.parentElement;
      p.scrollIntoView();
    }
  }

  // getAllMessages() {
  //   const messaging = firebase.messaging();
  //   messaging
  //     .requestPermission()
  //     .then(() => {
  //       console.log("Have Permision");
  //       return messaging.getToken();
  //     })
  //     .then(token => {
  //       console.log(token);
  //     })
  //     .catch(er => {
  //       console.log(`Error Occured. ${er}`);
  //     });

  //   messaging.onMessage(payload => {
  //     console.log("Message received. ", payload);
  //     // ...
  //   });
  // }
}
